<template>
  <div
    class="container-main сsn-main-content csn-main-content-search-overlay"
    :class="{
      'csn-main-content-search-open': isSearchOpenAnimation,
      'csn-main-content-search-closed': isSearchClosedAnimation,
      'csn-main-content-search-open-no-animation': isSearchOpenNoAnimation,
      'csn-main-content-search-closed-no-animation': isSearchClosedNoAnimation,
    }"
  >
    <div class="container-style-1 csn-container">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  MAIN_CONTENT,
  Module,
  IS_MOBILE_SEARCH_INPUT_OPEN,
  IS_NAVBAR_AT_TOP,
} from '@/constants'

export default {
  name: MAIN_CONTENT,
  computed: {
    ...mapState(Module.MAIN, {
      IS_MOBILE_SEARCH_INPUT_OPEN,
      IS_NAVBAR_AT_TOP,
    }),
    isSearchOpenAnimation() {
      return this.IS_NAVBAR_AT_TOP && this.IS_MOBILE_SEARCH_INPUT_OPEN
    },
    isSearchOpenNoAnimation() {
      return !this.IS_NAVBAR_AT_TOP && this.IS_MOBILE_SEARCH_INPUT_OPEN
    },
    isSearchClosedAnimation() {
      return this.IS_NAVBAR_AT_TOP && this.IS_MOBILE_SEARCH_INPUT_OPEN === false
    },
    isSearchClosedNoAnimation() {
      return (
        !this.IS_NAVBAR_AT_TOP && this.IS_MOBILE_SEARCH_INPUT_OPEN === false
      )
    },
  },
}
</script>
